// extracted by mini-css-extract-plugin
export var mainVisual = "index-module--main-visual--11z0q";
export var mainVisualImg = "index-module--main-visual-img--1NrI4";
export var mainText = "index-module--main-text--2gEjV";
export var article = "index-module--article--1Cyz7";
export var section = "index-module--section--Dyjl7";
export var sectionHeader = "index-module--section-header--tNVaG";
export var sectionContainer = "index-module--section-container--18UPI";
export var sectionLeft = "index-module--section-left--4Hlq3";
export var sectionRight = "index-module--section-right--2YTXZ";
export var sectionImg = "index-module--section-img--13U82";
export var sectionLink = "index-module--section-link--3RsjG";